<template>
  <div>
    <div class="bannerContainer">
      <div class="w" :class="$i18n.locale == 'en' ? 'en_banner' : 'zh_banner'">
        <div class="title">{{ $t('AI条码秤解决方案') }}</div>
        <div class="info">
          {{ $t('算法、应用、硬件、OS及云平台，一站式解决方案。') }}
        </div>
      </div>
    </div>

    <div class="content">
      <div class="algorithmContainer">
        <div class="algorithm w">
          <div class="title">
            <div class="icon">
              <img src="../../../assets/AI条码秤解决方案/算法图标.png" alt="" />
            </div>
            <div class="title">{{ $t('算法') }}</div>
          </div>
          <div class="info">
            {{
              $t(
                '傲视AI算法拥有毫秒级的快速识别能力，支持多样性商品；核心专利，拥有离线自主识别和学习能力。'
              )
            }}
          </div>
        </div>
      </div>
      <div class="appContainer">
        <div class="app w">
          <div class="title">
            <div class="icon">
              <img src="../../../assets/AI条码秤解决方案/软件.png" alt="" />
            </div>
            <div class="title">{{ $t('应用') }}</div>
          </div>
          <div class="info">
            {{
              $t(
                '针对专属硬件深度优化应用，确保兼容性，更多自定义功能和竞争优势。'
              )
            }}
          </div>
        </div>
      </div>
      <div class="osContainer">
        <div class="os w">
          <div class="title">
            <div class="icon">
              <img src="../../../assets/AI条码秤解决方案/系统.png" alt="" />
            </div>
            <div class="title">{{ $t('OS') }}</div>
          </div>
          <div class="info">
            {{ $t('客制化系统固件，快速满足客户的行业需求。') }}
          </div>
        </div>
      </div>
      <div class="cloudPlatformContainer">
        <div class="cloudPlatform w">
          <div class="title">
            <div class="icon">
              <img src="../../../assets/AI条码秤解决方案/云端.png" alt="" />
            </div>
            <div class="title">{{ $t('云平台') }}</div>
          </div>
          <div class="info">
            <div>{{ $t('实时监控设备，远程管理终端。') }}</div>
            <div>{{ $t('多维度、多模块、跨平台，持续升级和迭代。') }}</div>
          </div>
        </div>
      </div>
      <div class="hardwareContainer">
        <div class="hardware w">
          <div class="title">
            <div class="icon">
              <img src="../../../assets/AI条码秤解决方案/硬件图标.png" alt="" />
            </div>
            <div class="title">{{ $t('硬件') }}</div>
          </div>
          <div class="info">
            <div>{{ $t('自主研发的硬件，') }}</div>
            <div>{{ $t('具有高稳定性和高专业性的优势。') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.bannerContainer {
  background: url(../../../assets/AI条码秤解决方案/AI条码秤解决方案配图.png)
    no-repeat center;
  background-size: cover;
  .zh_banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    .title {
      line-height: 104.26px;
      color: #344f98;
      font-size: 72px;
      font-weight: 700;
    }
    .info {
      color: #fff;
      font-size: 24px;
      height: 83px;
      font-size: 24px;
      line-height: 34.75px;
    }
  }
  .en_banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    .title {
      line-height: 72.4px;
      color: #344f98;
      font-size: 50px;
      font-weight: 700;
    }
    .info {
      width: 642px;
      color: #fff;
      font-size: 24px;
      font-size: 24px;
      line-height: 34.75px;
    }
  }
}
.content {
  .algorithmContainer {
    background: url(../../../assets/AI条码秤解决方案/算法.png) no-repeat center;
    background-size: cover;
    .algorithm {
      padding-left: 135px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      .title {
        align-items: center;
        display: flex;
        font-weight: 700;
        line-height: 57.92px;
        font-size: 40px;
        .icon {
          margin-right: 11px;
          width: 48.75px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .info {
        width: 443px;
        font-size: 24px;
        line-height: 34.75px;
        margin-left: 61px;
      }
    }
  }
  .appContainer {
    background: url(../../../assets/AI条码秤解决方案/应用.png) no-repeat center;
    background-size: cover;
    .app {
      padding-left: 135px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #344f98;
      .title {
        align-items: center;
        display: flex;
        font-weight: 700;
        line-height: 57.92px;
        font-size: 40px;
        .icon {
          margin-right: 14.09px;
          width: 46.96px;
          height: 46.86px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .info {
        width: 469px;
        font-size: 24px;
        line-height: 34.75px;
        margin-left: 64.09px;
      }
    }
  }
  .osContainer {
    background: url(../../../assets/AI条码秤解决方案/OS.png) no-repeat center;
    background-size: cover;
    .os {
      padding-left: 135px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #344f98;
      .title {
        align-items: center;
        display: flex;
        font-weight: 700;
        line-height: 57.92px;
        font-size: 40px;
        .icon {
          margin-right: 3px;
          width: 50px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .info {
        width: 488px;
        font-size: 24px;
        line-height: 34.75px;
        margin-left: 53px;
      }
    }
  }
  .cloudPlatformContainer {
    background: url(../../../assets/AI条码秤解决方案/云平台.png) no-repeat
      center;
    background-size: cover;
    .cloudPlatform {
      padding-left: 135px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #344f98;
      .title {
        align-items: center;
        display: flex;
        font-weight: 700;
        line-height: 57.92px;
        font-size: 40px;
        .icon {
          margin-right: 5px;
          width: 50px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .info {
        width: 579px;
        font-size: 24px;
        line-height: 34.75px;
        margin-left: 55px;
      }
    }
  }
  .hardwareContainer {
    background: url(../../../assets/AI条码秤解决方案/硬件.png) no-repeat center;
    background-size: cover;
    .hardware {
      padding-left: 135px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      .title {
        align-items: center;
        display: flex;
        font-weight: 700;
        line-height: 57.92px;
        font-size: 40px;
        .icon {
          margin-right: 11.36px;
          width: 48.57px;
          height: 48.21px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .info {
        width: 475px;
        font-size: 24px;
        line-height: 34.75px;
        margin-left: 61.36px;
      }
    }
  }
}
</style>
